<template>
  <LcHeader
    headline-class="flex items-center justify-center text-h3"
    :left-button-aria-label="$t('cms.header.button.goback')"
    left-button-icon="arrow-left"
    @click="navigateBack"
  >
    <template #headline>
      {{ title }}
    </template>

    <slot />
  </LcHeader>
</template>

<script lang="ts" setup>
import { LcHeader } from '@lottocom/frontend-components'

defineProps<{ title: string }>()

const { navigateBack } = useRouterUtils()
</script>
