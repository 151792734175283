<template>
  <div class="cms">
    <NuxtLayout name="sticky">
      <template #header>
        <CmsHeader v-bind="{ title }" />
      </template>

      <section v-if="page.length">
        <ContentfulRenderer
          v-for="(block, i) in page"
          :key="`block-${i}`"
          class="content"
          :data="block.content"
        />
      </section>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import type {
  CmsPageQuery,
  ContentBlock,
  Page,
} from '~/@types/generated/cms/graphql-schema-types'

const licensedTerritory = useLicensedTerritory()
const { path } = useRoute()

const { data: routes } = await useAsyncData(`routes_${path}`, () =>
  queryContent<{
    items: Page[]
  }>(`/contentful/pages/routes/${licensedTerritory}`)
    .findOne()
    .catch(),
)

const { data: pageContent } = await useAsyncData(`page_${path}`, () => {
  const pageToRender = routes.value?.items.find((page) => {
    if (!page.url) return false
    return path.includes(page.url)
  })

  return queryContent<CmsPageQuery['page']>(
    `/contentful/pages/${licensedTerritory}-${pageToRender?.url}`,
  )
    .findOne()
    .catch()
})

const page = computed(
  () =>
    pageContent.value?.contentBlocksCollection?.items.filter(isContentBlock) ??
    [],
)

const title = computed(() => pageContent.value?.title || '')

const isContentBlock = (property: unknown): property is ContentBlock =>
  (property as ContentBlock).__typename === 'ContentBlock'
</script>

<style lang="scss" scoped>
.cms {
  :deep(.content) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: spacing('2xs');
      font-size: $font-size-h5;
    }

    p {
      margin-bottom: spacing('md');
    }

    ul {
      margin-bottom: spacing('xl');
      padding-left: spacing('xl');
      list-style: disc;

      li {
        &::marker {
          content: '●  ';
          color: color('success500');
        }

        + li {
          margin-top: spacing('md');
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
